export default [
  {
    title: 'navbar.projectManagement',
    route: { name: 'projects' },
    icon: 'homeIcon',
  },
  {
    title: 'navbar.rsManagement',
    route: { name: 'rsManagement' },
    icon: 'building02',
    children: [
      {
        title: 'navbar.connected',
        route: {name: 'rsManagement.connected'},
        icon: 'circleIcon',
      },
      {
        title: 'navbar.sentRequest',
        route: {name: 'rsManagement.sentRequest'},
        icon: 'circleIcon',
      },
      {
        title: 'navbar.receivedRequest',
        route: {name: 'rsManagement.receivedRequest'},
        icon: 'circleIcon',
      }
    ]
  },
  {
    title: 'navbar.appointmentManagement',
    route: {name: 'appointment'},
    icon: 'calendarTick',
    children: [
      {
        title: 'navbar.tableView',
        route: {name: 'appointment.tableView'},
        icon: 'circleIcon',
      },
      {
        title: 'navbar.calendarView',
        route: {name: 'appointment.calendarView'},
        icon: 'circleIcon',
      },
    ]
  },
  {
    title: 'navbar.internalAgentManagement',
    route: { name: 'internalAgent' },
    icon: 'peopleIcon2',
  },
  {
    title: 'navbar.leadDetails',
    route: { name: 'leadDetails' },
    icon: 'userSquareIcon',
  },
  {
    title: 'navbar.contactRequests',
    route: { name: 'contactRequests' },
    icon: 'userAddIcon',
  },
  {
    title: 'navbar.onlineSetting',
    route: { name: 'contactRequests' },
    icon: 'settingIcon2',
    children: [
      {
        title: 'navbar.branding',
        route: { name: 'onlineSetting.branding' },
        icon: 'circleIcon',
      },
      {
        title: 'navbar.media',
        route: { name: 'onlineSetting.media' },
        icon: 'circleIcon',
      },
      {
        title: 'navbar.aboutUs',
        route: { name: 'onlineSetting.aboutUs' },
        icon: 'circleIcon',
      }
    ]
  }
]
