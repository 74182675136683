<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabledm
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <SvgLoader :name="item.icon" />
      <span class="menu-title text-truncate fd-mt-1">{{ $t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        localStorage.removeItem('token')
        return this.$router.push('/')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-item {
  font-size: 14px !important;
  font-weight: 600 !important;
  & ::v-deep svg {
    path {
      fill: #B5B8B5 !important;
    }
  }
  &.active ::v-deep svg {
    path{
      fill: #202A21 !important;
    }
  }
  &.sidebar-group-active ul.menu-content .nav-item.active ::v-deep svg {
    path {
      fill: #ffffff !important;
    }
    circle {
      stroke: #ffffff !important;
    }
  }
  &.sidebar-group-active ul.menu-content .nav-item ::v-deep svg {
    path {
      fill: #202A21 !important;
    }
  }
}
.navigation li a i, .navigation li a svg {
  width: 24px !important;
  height: 24px !important;
  margin-right: 1rem !important;
  //margin-left: 2px !important;
}
.li-custom {
  //position: absolute !important;
  top: 150px !important;
}
</style>